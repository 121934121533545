//use temp bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#home-slider {
  @apply overflow-y-hidden;
  position: relative;
  background-color: theme("colors.green.dark");
  height: 450px;

  @screen sm {
    height: auto;
  }

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    color: theme("colors.white");
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;

    .p1 {
      font-size: 36px;
      @include media-breakpoint-down(lg) {
        font-size: 32px;
      }
      @include media-breakpoint-down(md) {
        font-size: 28px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }
    }

    img,
    picture {
      @apply w-full h-full object-cover;
    }

    .p2 {
      font-size: 54px;
      padding: 5px 0 30px;
      @include media-breakpoint-down(lg) {
        font-size: 50px;
        padding-bottom: 20px;
      }
      @include media-breakpoint-down(md) {
        font-size: 36px;
        padding-bottom: 13px;
      }
    }

    .swiper-content {
      position: relative;
      z-index: 2;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }

    @include media-breakpoint-down(xs) {
      /*height: 87.8vw;*/
      background-size: contain;
      background-repeat: no-repeat;

      .swiper-content {
        max-width: 245px;
      }
    }
  }
}

.slide-url {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

section.video {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 500px;
  margin-bottom: 95px;

  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;

  &.bg-video:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.2);
  }

  .btn {
    position: relative;
    z-index: 2;
  }

  video {
    width: 100%;
    max-width: 800px;
    height: auto;
    display: block;
    animation: fasdein 1s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.active {
    height: auto;
  }
}

.home-actus {
  padding-bottom: 55px;
  position: relative;
  @include media-breakpoint-down(xs) {
    padding-bottom: 30px;
  }

  .btn-noir {
    margin-top: 30px;
    @include media-breakpoint-down(xs) {
      @apply bg-red-light;
      margin-top: 55px;
      &:after {
        @apply bg-red;
      }
      span {
        color: theme("colors.white");
      }
    }
  }

  .img-info {
    padding-right: 28px;
  }

  .titre {
    margin-bottom: 30px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }

  .swiper-pagination {
    position: absolute;
    top: 95px;
    left: 0;
    width: 100%;
    height: 30px;
    text-align: center;
    z-index: 20 !important;

    @include media-breakpoint-down(xs) {
      top: auto;
      bottom: 100px;
      background-color: theme("colors.pink");
      padding-bottom: 30px;
    }

    .swiper-pagination-bullet {
      background-color: theme("colors.gray.dark");
      opacity: 1;
      width: 13px;
      height: 13px;
      margin: 0 5px;
      outline: none !important;

      &.swiper-pagination-bullet-active {
        background-color: theme("colors.white");
        @include media-breakpoint-down(xs) {
          background-color: theme("colors.red.light");
        }
      }
    }
  }

  .swiper-slide {
    width: calc(100% / 3 - 30px);
    margin-right: 30px;
  }

  @include media-breakpoint-down(md) {
    .teaser-actu .visu,
    .thumbnail-actu .actu .visu {
      min-height: 200px;
    }
    .swiper-slide {
      width: calc(100% / 2 - 20px);
      margin-right: 20px;
    }
  }
  @include media-breakpoint-down(sm) {
    .teaser-actu .visu,
    .thumbnail-actu .actu .visu {
      min-height: 150px;
    }
    .swiper-slide {
      width: 100%;
      margin-right: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    .teaser-actu .visu,
    .thumbnail-actu .actu .visu {
      min-height: 230px;
    }
  }
}

section.pizzas {
  max-width: 100vw;
  overflow: hidden;

  .container-list-pizza {
    position: relative;
    background-color: theme("colors.green.DEFAULT");
    overflow: hidden;
  }

  .section-pizza-titre {
    position: relative;
    text-align: center;
    padding: 30px 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 27px;
    border-bottom: 1px solid theme("colors.red.DEFAULT");

    @include media-breakpoint-down(xs) {
      font-size: 25px;
    }

    &.arrow {
      text-transform: initial;
      text-align: center;
      cursor: pointer;

      p {
        display: inline-block;
        position: relative;
        padding-right: 70px;
        @include media-breakpoint-down(xs) {
          display: block;
          padding-right: 40px;
          font-size: 24px;
          text-align: left;
        }

        &:after {
          content: ">";
          color: theme("colors.white");
          display: inline-block;
          width: 35px;
          height: 35px;
          line-height: 32px;
          text-align: center;
          position: absolute;
          right: 0;
          border-radius: 50%;
          background-color: theme("colors.red.DEFAULT");
          transition: all 190ms ease;
          transform: rotate(0);
        }

        &.open {
          &:after {
            transform: rotate(90deg);
          }
        }
      }

      small {
        font-size: 16px;
      }

      &:after {
        display: none !important;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 185px 0 185px;
      border-color: theme("colors.red.light") transparent transparent transparent;
      top: 99%;
      left: 50%;
      transform: translate(-50%, 0);
      @include media-breakpoint-down(xs) {
        border-width: 21px 160px 0 160px;
      }
    }
  }

  .pagination-pizza {
    display: none;
    width: 100%;
    top: calc(50% + 43px);
    height: 45px;
    position: absolute;
    left: 0;
    z-index: 100;
    pointer-events: none;

    @include media-breakpoint-down(xs) {
      &.pagination-pizza-0 {
        display: block;
      }
    }

    span {
      height: 45px;
      display: block;
      width: 30px;
      background-color: theme("colors.black.light");
      color: theme("colors.white");
      z-index: 5;
      text-align: center;
      line-height: 45px;
      pointer-events: auto;
      opacity: 1;

      transition: all 300ms ease;

      &:nth-child(1) {
        float: left;
      }

      &:nth-child(2) {
        float: right;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none;
      }

      &.swiper-button-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .list-pizza {
    background-color: #fff;
    transition: all 300ms ease;

    .swiper-wrapper {
      display: block !important;
      margin-bottom: 0;
    }

    .teaser-pizza {
      /*min-height: 20vw;*/

      &:nth-of-type(even) {
        background-color: theme("colors.green.DEFAULT");
      }

      @include media-breakpoint-down(lg) {
        /*min-height: 300px;*/
      }
    }
  }

  .list-pizza--desk {
    /*display: flex;
    flex-wrap: wrap;*/

    .teaser-pizza {
      width: 19.9%;

      @include media-breakpoint-down(lg) {
        width: 33.3%;
        /*min-height: 300px;*/
      }
      @include media-breakpoint-down(sm) {
        width: 49.9%;
      }
    }

    .teaser-pizza--promo {
      width: 39.9% !important;

      @include media-breakpoint-down(md) {
        width: 66.6% !important;
      }
      @include media-breakpoint-down(sm) {
        width: 49.9% !important;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
}

.home__kiosk {
  display: inline-flex;
  flex-direction: column;
}

.home__my-kiosk {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
  }

  p {
    width: auto;
    margin: 0;
    padding: 0 0 0 30px;

    text-align: left;
    font-size: 18px;
  }
}

.home__discover-kiosk {
  margin-bottom: 15px;
}

.trouver-kiosques {
  @include media-breakpoint-down(sm) {
    &:before,
    &:after {
      display: none;
    }
    .container {
      max-width: 100%;
    }
  }

  .no-kiosk-sentence {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    width: 375px;
    max-width: 100%;
    margin: 0 auto;
  }

  .content-kiosk {
    @apply flex flex-col justify-between;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    .btn {
      margin-top: 15px;
    }
  }
}

.bloc-enseigne-parent {
  padding-top: 50px;
}

.bloc-enseigne {
  width: calc((100% - 30px) / 4);
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;

  @apply bg-green-extradark;

  &:hover {
    @apply bg-red;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
  }

  @include media-breakpoint-down(lg) {
    height: 225px;
  }
  @include media-breakpoint-down(md) {
    height: 165px;

    h2 {
      font-size: 18px;
    }
  }
  @include media-breakpoint-down(sm) {
    width: calc((100% - 10px) / 2);
    height: 250px;

    h2 {
      font-size: 24px;
    }
  }
  @include media-breakpoint-down(xs) {
    width: 45%;
    height: 185px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 5%;

    h2 {
      font-size: 16px;
    }
  }

  svg {
    margin: 0 auto 20px auto;

    &.like {
      path {
        fill: theme("colors.yellow.DEFAULT");
      }
    }
  }

  path,
  polygon,
  circle,
  polyline {
    stroke: theme("colors.yellow.DEFAULT");
  }
}

.ouvrir-kiosque {
  position: fixed;
  right: 0;
  top: 200px;
  width: 144px;
  z-index: 200;
  @include media-breakpoint-down(md) {
    width: auto;
    top: 30px;
    display: none;
  }

  a {
    @apply text-white transition-all ease-in-out duration-200;
    display: block;
    background-color: theme("colors.red.light");
    text-transform: uppercase;
    padding: 20px;
    font-size: 15px;
    font-weight: 700;
    @include media-breakpoint-down(md) {
      padding: 15px;
      font-size: 13.5px;
    }

    &:hover {
      background-color: theme("colors.green.dark");
    }

    span {
      display: block;
      font-size: 20px;
      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
  }
}
